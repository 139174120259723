
import invariant from 'tiny-invariant';

function transformOrder<T extends { id: string }>(locale: string, orders: T[]): (T & { href: string })[] {
  invariant(Array.isArray(orders), 'transformOrder requires orders to be defined');

  const buildHref = (order: T): string => {
    return `/profil/objednavky/${order.id}`;
  };

  return orders.map((order: T) => ({
    ...order,
    href: buildHref(order),
  }));
}

export default transformOrder;